<template>
  <div class="flow-root">
    <ul class="-mb-8">
      <li
        v-for="(activityItem, activityItemIdx) in activities"
        :key="activityItem.id"
      >
        <div class="relative pb-8">
          <span
            v-if="activityItemIdx !== activities.length - 1"
            class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
          <div class="relative flex items-start space-x-3">
            <template v-if="activityItem.type === 'comment'">
              <div class="relative">
                <img
                  class="
                    h-10
                    w-10
                    rounded-full
                    bg-gray-400
                    flex
                    items-center
                    justify-center
                    ring-8 ring-white
                    object-cover
                  "
                  src="../assets/logo.png"
                  alt=""
                />

                <span
                  class="
                    absolute
                    -bottom-0.5
                    -right-1
                    bg-white
                    rounded-tl
                    px-0.5
                    py-px
                  "
                >
                  <ChatIcon class="h-5 w-5 text-green-600" aria-hidden="true" />
                </span>
              </div>
              <div class="min-w-0 flex-1">
                <div>
                  <div class="text-sm">
                    <a
                      :href="activityItem.person.href"
                      class="font-medium text-gray-900"
                      >{{ activityItem.person.name }}</a
                    >
                  </div>
                  <p class="mt-0.5 text-sm text-gray-500">
                    {{ activityItem.date }}
                  </p>
                </div>
                <div class="mt-2 text-sm text-gray-700">
                  <p>
                    {{ activityItem.comment }}
                  </p>
                </div>
                <div class="mt-6">
                  <router-link
                    :to="{ name: 'actions-active' }"
                    v-if="activityItem.document"
                    class="relative z-0 inline-flex shadow-sm rounded-md"
                  >
                    <button
                      type="button"
                      class="
                        relative
                        inline-flex
                        items-center
                        px-4
                        py-2
                        rounded-l-md
                        border border-gray-300
                        bg-light-blue-600
                        text-sm
                        font-medium
                        text-white
                        hover:bg-light-blue-700
                        focus:z-10
                        focus:outline-none
                        focus:ring-1 focus:ring-light-blue-500
                        focus:border-light-blue-500
                      "
                    >
                      <DocumentDuplicateIcon
                        class="-ml-1 mr-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Complete the actions
                    </button>
                    <button
                      type="button"
                      class="
                        -ml-px
                        relative
                        inline-flex
                        items-center
                        px-3
                        py-2
                        rounded-r-md
                        border border-gray-300
                        bg-light-blue-600
                        text-sm
                        font-medium
                        text-white
                        hover:bg-light-blue-700
                        focus:z-10
                        focus:outline-none
                        focus:ring-1 focus:ring-light-blue-500
                        focus:border-light-blue-500
                      "
                    >
                      {{actionsCount}}
                    </button>
                  </router-link>
                </div>
              </div>
            </template>
            <template
              v-else-if="activityItem.type === 'assignment'"
              condition="activityItem.type === 'assignment'"
            >
              <div class="relative">
                <img
                  class="
                    h-10
                    w-10
                    rounded-full
                    bg-gray-400
                    flex
                    items-center
                    justify-center
                    ring-8 ring-white
                    object-cover
                  "
                  src="../assets/logo.png"
                  alt=""
                />

                <span
                  class="
                    absolute
                    -bottom-0.5
                    -right-1
                    bg-white
                    rounded-tl
                    px-0.5
                    py-px
                  "
                >
                  <CheckCircleIcon
                    class="h-5 w-5 text-green-600"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div class="min-w-0 flex-1">
                <div>
                  <div class="text-sm">
                    <a
                      :href="activityItem.person.href"
                      class="font-medium text-gray-900"
                      >{{ activityItem.person.name }}</a
                    >
                  </div>
                  <p class="mt-0.5 text-sm text-gray-500">
                    {{ activityItem.date }}
                  </p>
                </div>
                <div class="mt-2 text-sm text-gray-700">
                  <p>
                    {{ activityItem.comment }}
                  </p>
                </div>
              </div>
            </template>
            <template
              v-else-if="activityItem.type === 'tags'"
              condition="activityItem.type === 'tags'"
            >
              <div>
                <div class="relative px-1">
                  <div
                    class="
                      h-8
                      w-8
                      bg-gray-100
                      rounded-full
                      ring-8 ring-white
                      flex
                      items-center
                      justify-center
                    "
                  >
                    <TagIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                  </div>
                </div>
              </div>
              <div class="min-w-0 flex-1 py-0">
                <div class="text-sm leading-8 text-gray-500">
                  <span class="mr-0.5">
                    <a
                      :href="activityItem.person.href"
                      class="font-medium text-gray-900"
                      >{{ activityItem.person.name }}</a
                    >
                    {{ " " }}
                    added tags
                  </span>
                  {{ " " }}
                  <span class="mr-0.5">
                    <template v-for="tag in activityItem.tags" :key="tag.name">
                      <a
                        :href="tag.href"
                        class="
                          relative
                          inline-flex
                          items-center
                          rounded-full
                          border border-gray-300
                          px-3
                          py-0.5
                          text-sm
                        "
                      >
                        <span
                          class="
                            absolute
                            flex-shrink-0 flex
                            items-center
                            justify-center
                          "
                        >
                          <span
                            :class="[tag.color, 'h-1.5 w-1.5 rounded-full']"
                            aria-hidden="true"
                          />
                        </span>
                        <span class="ml-3.5 font-medium text-gray-900">{{
                          tag.name
                        }}</span>
                      </a>
                      {{ " " }}
                    </template>
                  </span>
                  <span class="whitespace-nowrap">{{ activityItem.date }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  ChatIcon,
  TagIcon,
  CheckCircleIcon,
  DocumentDuplicateIcon,
} from "@heroicons/vue/solid";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  components: {
    CheckCircleIcon,
    ChatIcon,
    TagIcon,
    DocumentDuplicateIcon,
  },
  props: {
    activities: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    return {
      actionsCount: computed(() => store.getters["action/actionsCount"]),
    };
  },
};
</script>

<style>
</style>