<template>
  <div
    v-if="activeCase.id"
    class="xl:grid-cols-3 lg:grid-cols-3 grid gap-8 items-start"
  >
    <div class="col-span-2 space-y-8">
      <div class="bg-white overflow-hidden sm:rounded-lg sm:shadow">
        <div
          class="
            bg-gray-100
            px-4
            py-5
            border-b border-gray-200
            sm:px-6
            flex
            justify-between
            items-baseline
            flex-wrap
            gap-5
          "
        >
          <h3 class="text-2xl font-bold leading-8 max-w-sm text-gray-900">
            {{ activeCase.info.app_type.name }} of
            {{ activeCase.info.address.street_address }}
          </h3>
          <div
            class="
              relative
              bg-gray-200
              rounded-lg
              p-0.5
              flex
              lg:flex-initial
              md:flex-initial
              flex-1
            "
          >
            <button
              v-for="item in cases.items"
              @click="activeCase = item"
              :key="item.id"
              type="button"
              :class="[
                `
                relative
                border-gray-200
                rounded-md
                shadow-sm
                py-2
                w-1/2
                text-sm
                font-medium
                text-gray-900
                whitespace-nowrap
                focus:outline-none
                focus:ring-2 focus:ring-blue-500
                focus:z-10
                sm:w-auto
                sm:px-8`,
                activeCase.id === item.id && 'bg-white',
              ]"
            >
              {{ item.info.app_type.name }}
            </button>
          </div>
        </div>
        <div class="px-4 py-6">
          <ol class="flex">
            <li
              class="inline-grid w-full"
              :key="stage.id"
              v-for="(stage, k) in activeStages"
            >
              <button
                @click="activeStage = stage"
                :class="`bg-${stage.color}-${stage.color_tone}
               h-4
               ${k === 0 && 'rounded-l-xl'} 
               ${k === activeStages.length - 1 && 'rounded-r-xl'} 
               shadow
               focus-within:outline-none
               focus-within:ring-2
               focus-within:z-10
               focus-within:ring-${stage.color}-${+stage.color_tone + 200}
              ${
                stage.id === activeCase.info.stage.id &&
                `ring-2 z-10 ring-${stage.color}-${+stage.color_tone + 200}`
              }
              `"
              ></button>
            </li>
          </ol>
          <div class="flex-shrink-0 flex flex-col mt-6">
            <span
              :class="`
                px-2
                inline-flex
                max-w-max
                text-xs
                leading-5
                font-semibold
                rounded-full
                bg-${activeStage.color}-100
                text-${activeStage.color}-600
              `"
            >
              <small class="mr-1">●</small> {{activeCase.info.stage.order === activeStage.order ? 'Current' :null}} Stage {{ activeStage.order }}
            </span>
            <div class="grid lg:grid-cols-3 md:grid-cols-3 mt-3 gap-3">
              <h1
                class="
                  col-span-1
                  text-2xl
                  leading-8
                  font-medium
                  text-gray-900
                  pr-4
                "
              >
                {{ activeStage.name }}
              </h1>
              <p class="col-span-2 text-gray-600 text-xs leading-4">
                {{ activeStage.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Activities :activities="activity" />
    </div>
    <ul class="grid gap-6">
      <li
        class="
          col-span-1
          flex flex-col
          text-center
          bg-white
          rounded-lg
          shadow
          divide-y divide-gray-200
        "
      >
        <div class="flex-1 flex flex-col p-8">
          <img
            class="
              w-32
              h-32
              flex-shrink-0
              mx-auto
              bg-black
              rounded-full
              object-cover
            "
            :src="person.imageUrl"
            alt=""
          />
          <h3 class="mt-6 text-gray-900 text-sm font-medium">
            {{ person.name }}
          </h3>
          <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dt class="sr-only">Title</dt>
            <dd class="text-gray-500 text-sm">{{ person.title }}</dd>
            <dt class="sr-only">Role</dt>
            <dd class="mt-3">
              <span
                class="
                  px-2
                  py-1
                  text-green-800 text-xs
                  font-medium
                  bg-green-100
                  rounded-full
                "
                >{{ person.role }}</span
              >
            </dd>
          </dl>
        </div>
        <div>
          <div class="-mt-px flex divide-x divide-gray-200">
            <div class="w-0 flex-1 flex">
              <a
                :href="`mailto:${person.email}`"
                class="
                  relative
                  -mr-px
                  w-0
                  flex-1
                  inline-flex
                  items-center
                  justify-center
                  py-4
                  text-sm text-gray-700
                  font-medium
                  border border-transparent
                  rounded-bl-lg
                  hover:text-gray-500
                "
              >
                <MailIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                <span class="ml-3">Message</span>
              </a>
            </div>
            <div class="-ml-px w-0 flex-1 flex">
              <a
                :href="`tel:${person.telephone}`"
                class="
                  relative
                  w-0
                  flex-1
                  inline-flex
                  items-center
                  justify-center
                  py-4
                  text-sm text-gray-700
                  font-medium
                  border border-transparent
                  rounded-br-lg
                  hover:text-gray-500
                "
              >
                <PhoneIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                <span class="ml-3">Call</span>
              </a>
            </div>
          </div>
        </div>
      </li>
      <li
        class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
      >
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1 truncate">
            <div class="flex items-center space-x-3">
              <h3 class="text-gray-900 text-sm font-medium truncate">
                {{ solicitor.first_name }} {{ solicitor.last_name }}
              </h3>
              <span
                class="
                  flex-shrink-0
                  inline-block
                  px-2
                  py-0.5
                  text-green-800 text-xs
                  font-medium
                  bg-green-100
                  rounded-full
                "
                >Active</span
              >
            </div>
            <p class="mt-1 text-gray-500 text-sm truncate">
              Solicitor/Director
            </p>
          </div>
          <img
            class="
              w-10
              h-10
              bg-gray-300
              rounded-full
              flex-shrink-0
              object-cover
            "
            :src="`https://source.boringavatars.com/beam/120/${solicitor.email}?colors=0C4A6E,0284C7,D1FAE5,0284C7,e76f51`"
            alt=""
          />
        </div>
        <div>
          <div class="-mt-px flex divide-x divide-gray-200">
            <div class="w-0 flex-1 flex">
              <a
                :href="`mailto:${solicitor.email}`"
                class="
                  relative
                  -mr-px
                  w-0
                  flex-1
                  inline-flex
                  items-center
                  justify-center
                  py-4
                  text-sm text-gray-700
                  font-medium
                  border border-transparent
                  rounded-bl-lg
                  hover:text-gray-500
                "
              >
                <MailIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                <span class="ml-3">Message</span>
              </a>
            </div>
            <div class="-ml-px w-0 flex-1 flex">
              <a
                :href="`tel:${solicitor.phone}`"
                class="
                  relative
                  w-0
                  flex-1
                  inline-flex
                  items-center
                  justify-center
                  py-4
                  text-sm text-gray-700
                  font-medium
                  border border-transparent
                  rounded-br-lg
                  hover:text-gray-500
                "
              >
                <PhoneIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                <span class="ml-3">Call</span>
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { MailIcon, PhoneIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";
import { onMounted, computed, ref } from "vue";

import Activities from "@/components/Activities";
import { getStages } from "../../services/case";

export default {
  components: {
    MailIcon,
    PhoneIcon,
    Activities,
  },
  setup() {
    const store = useStore();
    const stages = ref([]);
    const activeCase = ref({});
    const activeStage = ref({});
    onMounted(async () => {
      const data = await store.dispatch("cases/getMyCases");
      const { data: stagesData } = await getStages();
      stages.value = stagesData;
      activeCase.value = data[0];
      activeStage.value = data[0].info.stage;
    });

    const activity = [
      {
        id: 1,
        type: "comment",
        document: true,
        person: { name: "Indy Deu", href: "#" },
        imageUrl:
          "https://www.ajsolicitors.com/website/wp-content/uploads/2015/01/indy11.jpg",
        comment: `Hi ${store.state.user.user.first_name}, I’ll be guiding you in your selling process.`,
        date: "Today 4:50 pm",
      },
      {
        id: 2,
        type: "assignment",
        comment: "Indy Deu assigned to your case.",
        imageUrl:
          "https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
        person: { name: "Ashley James Solicitors", href: "#" },
        assigned: { name: "Kristin Watson", href: "#" },
        date: "Today 4:35 pm",
      },
      {
        id: 4,
        type: "comment",
        person: { name: "Ashley James Solicitors", href: "#" },
        imageUrl:
          "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
        comment: `Your application successfully submitted. We are assigning a solicitor to your case.`,
        date: "Today 4:50 pm",
      },
    ];
    return {
      activity,
      activeCase,
      activeStage,
      activeStages: computed(() =>
        stages.value
          .filter((i) => i.app_type.id === activeCase.value.info.app_type.id)
          .sort((a, b) => a.order - b.order)
      ),
      cases: computed(() => store.state.cases.cases),
      solicitor: computed(() => store.state.user.user.info.solicitor),
      person: {
        name: "Indy Deu",
        title: "Your Solicitor",
        role: "Active",
        email: "janecooper@example.com",
        telephone: "+1-202-555-0170",
        imageUrl:
          "https://www.ajsolicitors.com/website/wp-content/uploads/2015/01/indy11.jpg",
      },
    };
  },
};
</script>
<style></style>
